import { useLayoutEffect, useState } from "react";

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);

	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	return { viewPortWidth: size[0] };
}

export default useWindowSize;
